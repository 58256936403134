import React, { ReactElement, useEffect } from 'react';
import {
  Box,
  Tab,
  tabClasses,
  Tabs,
  Typography,
  Stack,
  IconButton,
  Container,
  Paper,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/styles';
import { useAuth } from '../../auth/auth-hook';
import { CustomHeader } from '../../../shared/components/layout/CustomHeader';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ROUTES = [
  {
    path: '/global-users/:id',
    name: 'Detalles del usuario',
  },
  {
    path: '/global-users/:id/history',
    name: 'Historial',
  },
];

type SettingLayoutProps = {
  children: ReactElement;
  withoutPaper?: boolean;
};

export const TenantUserDetailsLayout: React.FC<SettingLayoutProps> = ({
  children,
  withoutPaper,
}) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const currentRoute = ROUTES.find((route) => route.path === location.pathname);
  const { user, tenant } = useAuth();

  const TabItem = styled(Tab)({
    position: 'relative',
    borderRadius: '30px !important',
    textAlign: 'center',
    transition: 'all .5s',
    padding: '8px 10px !important',
    color: '#BBBBBB',
    height: '35px !important',
    margin: '6px 2px',
    float: 'none',
    lineHeight: '14px',
    fontSize: '14px',
    fontWeight: '700',
    minHeight: '38px',
    textTransform: 'capitalize',
    [`&.${tabClasses.selected}, &:hover`]: {
      color: tenant?.identityColor ?? 'black',
      backgroundColor: tenant?.identityColor + '20' ?? 'black',
    },
  });

  useEffect(() => {
    ROUTES.forEach((route, i) => {
      const path = route.path.replace(':id', id as string);
      if (path === location.pathname) {
        setCurrentTab(i + 1);
      }
    });
  });

  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: number | null,
  ): void => {
    setCurrentTab(newValue ?? 0);
  };
  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={
          'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
        }
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          padding: '16px 16px',
          background: 'white',
        }}>
        <Stack direction="column" width={'100%'} spacing={2}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              paddingBottom: 1,
            }}>
            <Tabs
              sx={{
                padding: { sm: '0px 10px !important' },

                ['& .MuiTabs-indicator']: {
                  display: 'none !important',
                },
              }}
              value={currentTab}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              variant="scrollable"
              allowScrollButtonsMobile>
              <IconButton
                onClick={() => navigate('/global-users')}
                sx={{ width: '30px', height: '30px', my: '10px' }}>
                <ArrowBackIosIcon sx={{ fontSize: 14 }} />
              </IconButton>

              {ROUTES.map((route, key) => {
                const path = route.path.replace(':id', id as string);

                return (
                  <TabItem
                    key={key}
                    label={route.name}
                    onClick={() => navigate(path)}
                  />
                );
              })}
            </Tabs>
          </Box>
          {!withoutPaper ? (
            <Paper variant="outlined">
              <Box width="100%" py={'16px'}>
                {currentRoute?.name !== 'Usuarios' &&
                currentRoute?.name !== 'Invitaciones' ? (
                  <Box>
                    <Typography
                      sx={{ textAlign: { xs: 'center', md: 'start' } }}
                      fontSize={{ xs: '18px', md: '22px' }}
                      fontWeight={700}>
                      {currentRoute?.name}
                    </Typography>
                  </Box>
                ) : null}
                <Box>{children}</Box>
              </Box>
            </Paper>
          ) : (
            <Box width="100%">
              {currentRoute?.name !== 'Usuarios' &&
              currentRoute?.name !== 'Invitaciones' ? (
                <Box>
                  <Typography
                    sx={{ textAlign: { xs: 'center', md: 'start' } }}
                    fontSize={{ xs: '18px', md: '22px' }}
                    fontWeight={700}>
                    {currentRoute?.name}
                  </Typography>
                </Box>
              ) : null}
              <Box>{children}</Box>
            </Box>
          )}
        </Stack>
      </Container>
    </>
  );
};
