import React, { ChangeEvent } from 'react';
import {
  TextField,
  OutlinedTextFieldProps,
  Box,
  Typography,
  MenuItem,
} from '@mui/material';

interface InputDefaultProps extends OutlinedTextFieldProps {
  onChangeData?: (field: string, value: string) => void;
  labelName?: string;
  options?: { value: string; label: string }[];
}

export const SelectDefault: React.FC<InputDefaultProps> = ({
  onChangeData,
  size = 'small',
  labelName = '',
  variant = 'outlined',
  options = [],
  value = '',
  ...rest
}) => {
  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    if (onChangeData) onChangeData(event.target.name, event.target.value);
  };

  return (
    <Box width="100%">
      {labelName && <Typography>{labelName}</Typography>}
      <TextField
        size={size}
        fullWidth
        onChange={onChange}
        color={'primary'}
        variant={variant}
        select
        value={value}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: 19 },
        }}
        InputProps={{
          style: {
            height: 48,
            borderRadius: '8px',
            background: '#F6F6F6',
          },
        }}
        SelectProps={{
          style: {
            height: 48,
            borderRadius: '8px',
            background: '#F6F6F6',
          },
        }}
        {...rest}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
