import React, { useEffect, useState } from 'react';
import { DashboardCardItem } from '../DashboardCardItem';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { fetchUserCount } from '../../../user/user-actions';
import { useAuth } from '../../../auth/auth-hook';

export const DashboardTotalUserItem: React.FC = () => {
  const [total, setTotal] = useState(0);
  const { tenant } = useAuth();
  const [callFetchUser, loading] = useCallAction(fetchUserCount, {
    onCompleted: (data) => {
      setTotal(data.count || 0);
    },
  });

  useEffect(() => {
    callFetchUser({ tenant: tenant });
  }, [callFetchUser, tenant]);

  return (
    <DashboardCardItem
      title="Total usuarios registrados"
      content={total}
      loading={loading}
    />
  );
};
