import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { openSnackbarAction } from '../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../shared/components/snackbar/snackbar-types';
import {
  TenantSubscriptionPlan,
  TenantSubsPlanDetailsTabs,
} from './tenant-subs-plan-types';
import { fetchTenantSubscriptionPlan } from './tenant-subs-plan-actions';
import { useAuth } from '../auth/auth-hook';
import { TenantSubsPlanDetailsForm } from './components/TenantSubsPlanDetailsForm';
import { useDetailsPlanSteps } from './tenant-subs-plan-hooks';
import { Loading } from '../../shared/components/Loading';

export const TenantSubsPlanDetails: React.FC = () => {
  const { tenant } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [planDetails, setPlanDetails] = useState<TenantSubscriptionPlan | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  const { step, handleChangeStep } = useDetailsPlanSteps();

  const stepsView = {
    [TenantSubsPlanDetailsTabs.DETAILS]: (
      <TenantSubsPlanDetailsForm data={planDetails} loading={loading} />
    ),
  };

  const [callFetch] = useCallAction(fetchTenantSubscriptionPlan, {
    onCompleted: (data) => {
      setPlanDetails(data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
    onError: (error) => {
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  useEffect(() => {
    handleChangeStep(TenantSubsPlanDetailsTabs.DETAILS);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tenant && id) {
      setLoading(true);
      callFetch({ tenant, id });
    }
  }, [callFetch, tenant, id]);

  return (
    <Container maxWidth={'lg'} sx={{ py: '10px' }}>
      {loading || !planDetails ? <Loading height="400px" /> : stepsView[step]}
    </Container>
  );
};
