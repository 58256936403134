import moment from 'moment';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import { TenantSubsPlanStripeCouponType } from '../../tenant-subs-plan-types';
import { Box, IconButton, Typography } from '@mui/material';
import { TableDefault } from '../../../../shared/components/table/TableDefault';
import { formatNumber } from '../../../../shared/utils/number-utils';
import { openSnackbarAction } from '../../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../../shared/components/snackbar/snackbar-types';
import { useMemo, useState } from 'react';
import { ShareCouponModal } from './ShareCouponModal';
import { useCallAction } from '@cobuildlab/react-simple-state';
import {
  createSharedCoupon,
  editCoupon,
  enableOrDisableStripeCoupon,
} from '../coupon-actions';
import { useAuth } from '../../../auth/auth-hook';
import {
  ColumnStatus,
  ColumnTitle,
} from '../../../../shared/components/text/Typographys';
import { formatStatus } from '../../../../shared/utils/utils';
import { FileCopyOutlined as CopyIcon } from '@mui/icons-material/';
import { EditCouponModal } from './EditCouponModal';

interface CouponsTableProps {
  data: TenantSubsPlanStripeCouponType[];
  total: number;
  loading: boolean;
  pagination: {
    pageSize: number;
    page: number;
  };
  hanChangePagination: (value: { pageSize: number; page: number }) => void;
  refetch?: () => void;
  handleSort: (field: string, order: 'asc' | 'desc') => void;
}

export const CouponTable: React.FC<CouponsTableProps> = ({
  data,
  loading,
  total,
  pagination,
  hanChangePagination,
  refetch,
  handleSort,
}) => {
  const { tenant } = useAuth();
  const [isReferral, setIsReferral] = useState(false);
  const [openEditModal, setEditModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [currentCoupon, setCurrentCoupon] =
    useState<TenantSubsPlanStripeCouponType | null>(null);

  const [callEdit, loadingEdit] = useCallAction(editCoupon, {
    onCompleted: () => {
      openSnackbarAction('Cupón actualizado con éxito', TypeMessage.SUCCESS);
      setEditModal(false);
      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  const [callSharedCoupon, loadingSharedCoupon] = useCallAction(
    createSharedCoupon,
    {
      onCompleted: () => {
        setOpenShareModal(false);
        setCurrentCoupon(null);
        openSnackbarAction('Cupon compartido', TypeMessage.SUCCESS);
      },
      onError: (error) => {
        openSnackbarAction(error.message, TypeMessage.ERROR);
      },
    },
  );

  const [callToggleCoupon] = useCallAction(enableOrDisableStripeCoupon, {
    onCompleted: (event) => {
      const state = event.status === 'ACTIVE' ? 'activado' : 'desactivado';
      openSnackbarAction(`Cupon ${state} con exito.`, TypeMessage.SUCCESS);
      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  const copyToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text).then(() => {
      openSnackbarAction('Cupon copiado', TypeMessage.SUCCESS);
    });
  };

  const COLUMNS = useMemo(() => {
    const columns: TableColumnType<TenantSubsPlanStripeCouponType>[] = [
      {
        columnName: <ColumnTitle textAlign="left">Cupón</ColumnTitle>,
        columnValue: null,
        columnComponent: (item) => (
          <Box
            display={'flex'}
            textAlign="left"
            alignItems={'center'}
            sx={{
              fontWeight: 600,
            }}>
            <IconButton
              size="small"
              onClick={() => copyToClipboard(item.coupon)}>
              <CopyIcon fontSize="small" />
            </IconButton>
            {item.coupon}
          </Box>
        ),
      },
      {
        columnName: <ColumnTitle>Creado</ColumnTitle>,
        columnValue: (item) =>
          moment(item.created_at).format('DD/MM/YYYY') || '',
        sortableField: 'created_at',
      },
      {
        columnName: <ColumnTitle>Status</ColumnTitle>,
        columnValue: null,
        columnComponent: (item) => (
          <ColumnStatus active={item.status === 'ACTIVE'}>
            {item?.status ? formatStatus(item.status) : ''}
          </ColumnStatus>
        ),
      },
      {
        columnName: <ColumnTitle>Monto</ColumnTitle>,
        columnValue: (item) => formatNumber(item?.discount_amount ?? 0),
      },
      {
        columnName: <ColumnTitle>Descuento</ColumnTitle>,
        columnValue: (item) =>
          item?.discount_percent ? `${item.discount_percent}%` : '0%',
      },
      {
        columnName: <ColumnTitle>Uso max.</ColumnTitle>,
        columnValue: (item) => item?.max_redemptions ?? 0,
      },
      {
        columnName: <ColumnTitle>Limite</ColumnTitle>,
        columnValue: (item) =>
          item?.end_date ? moment(item.end_date).format('DD/MM/YYYY') : 'N/A',

        sortableField: 'end_date',
      },
    ];

    columns.push({
      columnName: <ColumnTitle>Duración (mes)</ColumnTitle>,
      columnValue: (item) =>
        item?.duration_in_months && item.duration_in_months > 1
          ? item.duration_in_months
          : 'N/A',
    });

    return columns;
  }, []);

  return (
    <Box
      style={{
        borderRadius: '16px',
        background: 'white',
        paddingTop: '16px',
        paddingBottom: '16px',
      }}>
      {currentCoupon && (
        <EditCouponModal
          open={openEditModal && !!currentCoupon}
          handleClose={() => {
            setEditModal(false);
            setCurrentCoupon(null);
          }}
          loading={loadingEdit}
          coupon={currentCoupon as TenantSubsPlanStripeCouponType}
          onSubmit={(dataForm) => {
            if (!tenant) return;
            callEdit({
              id: currentCoupon?.id as number,
              discountType:
                (currentCoupon?.discount_amount ?? 0) > 0
                  ? 'amount'
                  : 'percent',
              tenant,
              ...dataForm,
            });
          }}
        />
      )}
      <ShareCouponModal
        isReferral={isReferral}
        coupon={currentCoupon?.coupon ?? ''}
        open={openShareModal && !!currentCoupon}
        onClose={() => {
          setCurrentCoupon(null);
          setOpenShareModal(false);
        }}
        onSubmit={(emails) => {
          if (!currentCoupon || !tenant) return;

          callSharedCoupon({
            isReferral,
            emails,
            tenant,
            couponId: currentCoupon?.id as number,
          });
        }}
        loading={loadingSharedCoupon}
      />
      <TableDefault
        items={data}
        count={total}
        columns={COLUMNS}
        page={pagination.page}
        pageSize={pagination.pageSize}
        loading={loading}
        messageLoading={'Cargando cupones...'}
        handleSort={handleSort}

        handleChangePage={(page) => {
          hanChangePagination({
            ...pagination,
            page: page + 1,
          });
        }}
        showPagination={data.length > 0}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        handleChangeRowPerPage={(e) => {
          hanChangePagination({
            ...pagination,
            pageSize: parseInt(e.target.value, 10),
            page: 1,
          });
        }}
        tableCellActionsProps={{
          fontWeight: 500,
          color: 'rgba(187, 187, 187, 1)',
        }}
        actions={[
          {
            actionName: 'Actualizar',
            onClick: (item) => {
              setCurrentCoupon(item);
              setTimeout(() => {
                setEditModal(true);
              }, 500);
            },
          },
          {
            actionName: 'Compartir',
            onClick: (item) => {
              setIsReferral(false);
              setOpenShareModal(true);
              setCurrentCoupon(item);
            },
          },
          {
            actionName: 'Asignar para compartir',
            onClick: (item) => {
              setIsReferral(true);
              setOpenShareModal(true);
              setCurrentCoupon(item);
            },
          },
          {
            actionName: (item) =>
              item.status === 'ACTIVE' ? 'Desactivar' : 'Activar',
            onClick: (item) => {
              if (!tenant) return;
              callToggleCoupon({ tenant, couponId: item.id });
            },
          },
        ]}
      />
      {data.length === 0 && !loading && (
        <Box pt={3}>
          <Typography sx={{ textAlign: 'center' }} fontSize={'16px'}>
            No hay cupones disponibles
          </Typography>
        </Box>
      )}
    </Box>
  );
};
