import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Popover,
  Tooltip,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SelectDefault } from '../../../../shared/components/inputs/SelectDefault';
import { TENANT_SUBSCRIPTION_PLANS_STATUS } from '../../../../shared/constants';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import { makeStyles } from '@mui/styles';
import { couponFilterStoreAction } from '../coupon-actions';

const useStyles = makeStyles({
  root: {
    minWidth: '120px!important',
  },
});

const INITIAL_VALUES = {
  status: 'ACTIVE',
  expired: false,
};

export const CouponTableFilter: React.FC = () => {
  const classes = useStyles();
  const [filter, setFilter] = useState<{
    status: string;
    expired: boolean;
  }>(INITIAL_VALUES);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Tooltip title="Filtrar por status">
        <IconButton onClick={handleClick} style={{ padding: 0 }}>
          <FilterAltOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="simple-popover-coupon-table-filter"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Paper>
          <Box p={2} width={250}>
            <Box mb={2}>
              <SelectDefault
                variant="outlined"
                options={TENANT_SUBSCRIPTION_PLANS_STATUS}
                labelName="Status"
                value={filter.status}
                name="status"
                defaultValue=""
                onChangeData={(name, value) =>
                  setFilter({
                    ...filter,
                    [name]: value,
                  })
                }
              />
            </Box>
            <Box mb={2}>
              <FormControlLabel
                label="Incluir cupones vencidos"
                control={
                  <Checkbox
                    defaultChecked={INITIAL_VALUES.expired}
                    checked={filter.expired}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        expired: e.target.checked,
                      });
                    }}
                  />
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <ButtonDefault
                className={classes.root}
                variant="outlined"
                onClick={() => {
                  setFilter(INITIAL_VALUES);
                  couponFilterStoreAction(
                    INITIAL_VALUES.status,
                    INITIAL_VALUES.expired,
                  );
                  handleClose();
                }}>
                Limpiar
              </ButtonDefault>
              <ButtonDefault
                className={classes.root}
                onClick={() => {
                  couponFilterStoreAction(filter.status, filter.expired);
                  handleClose();
                }}>
                APLICAR
              </ButtonDefault>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};
