 import React from 'react';
import { useAuth } from '../../auth/auth-hook';
import { changeDashboardDateFilterStoreAction } from '../dashboard-actions';
import { Box, Grid, Typography } from '@mui/material';
import { DashboardGeneralDateText } from './DashboardGeneralDateText';
import { DateRangePicker } from '../../../shared/components/DateRangePicker';
import { formatterDateFilter } from '../../../shared/utils';
import { DateFilter } from '../../../shared/constants';
import { DashboardWallCrashesFunnel } from './DashboardWallCrashesFunnel';
import { DashboardWallCrashesByUserFunnel } from './DashboardWallCrashesByUserFunnel';

export const DashboardWallCrashesTab: React.FC = () => {
  const { tenant } = useAuth();

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Informe general de choques contra el muro</Typography>
              <Box display="flex" alignItems="center">
                <DashboardGeneralDateText />
                <DateRangePicker
                  onChange={(range) => {
                    const filters = formatterDateFilter(
                      range.startDate,
                      range.endDate,
                      range.key as DateFilter,
                    );
                    changeDashboardDateFilterStoreAction(
                      filters.startDate,
                      filters.endDate,
                      range.key as DateFilter,
                    );
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <DashboardWallCrashesFunnel />
          </Grid>
          <Grid item xs={12}>
            <DashboardWallCrashesByUserFunnel />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
