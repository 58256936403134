import React, { useEffect } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  AccordionProps,
  styled,
  Stack,
  TablePagination,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserEventsQuery } from '../../shared/types/generated';
import { getFullNameEvent } from './tenant-utils';
import moment from 'moment';
import { CardSection } from '../../shared/components/card/CardSection';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&::before': {
    display: 'none',
  },
}));

export const TenantUserDetailsHistory: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const { data } = useUserEventsQuery({
    variables: {
      userId: parseInt(id as string),
    },
  });

  const histories = data?.userEvents?.events || [];
  const count = data?.userEvents?.count || 0;

  return (
    <>
      <Box mb={3} mt={1}>
        <Typography fontSize={20} color="#BBBBBB" lineHeight="32px">
          Cantidad de eventos totales encontrados: {count}
        </Typography>
      </Box>
      <CardSection showCardHeader={false}>
        <Stack justifyContent="space-between" direction="row" mb={3} mt={2}>
          <Typography fontSize={16} color="#BBBBBB" lineHeight="24px">
            Nombre del evento
          </Typography>
          <Typography fontSize={16} color="#BBBBBB" lineHeight="24px">
            Fecha de evento
          </Typography>
        </Stack>

        {histories.map((history) => (
          <Accordion>
            <AccordionSummary sx={{ px: 0 }}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box>
                  <Typography fontSize={14} color="#000000" lineHeight="18px">
                    {getFullNameEvent(history?.name as string)}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={14} color="#000000" lineHeight="18px">
                    {moment(parseInt(history?.created_at as string)).format(
                      'DD-MM-YYYY HH:MM',
                    )}
                  </Typography>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box fontWeight="bold">user: {history?.user_id}</Box>

              {(history?.event_meta || [])?.map((meta) => (
                <Box>
                  <Typography fontSize={14} color="#000000" lineHeight="18px">
                    {`${meta?.meta_key} : ${meta?.meta_value}`}
                  </Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
        <TablePagination
          count={count}
          page={page - 1}
          rowsPerPage={pageSize}
          onPageChange={(event: unknown, newPage: number) =>
            setPage(newPage + 1)
          }
          slotProps={{
            actions: {
              nextButton: {
                style: {
                  padding: '0px 0px',
                  margin: '0px 0px',
                },
              },
              previousButton: {
                style: {
                  padding: '0px 0px',
                  margin: '0px 0px',
                },
              },
            },
          }}
          sx={{
            borderBottom: 'none',
            '.MuiToolbar-root': {
              mt: 0.5,
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          labelRowsPerPage="Filas/página"
          onRowsPerPageChange={(event) => {
            setPageSize(parseInt(event.target.value, 10));
          }}
        />
      </CardSection>
    </>
  );
};
