import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../auth/auth-hook';
import { Box, Grid, Typography } from '@mui/material';
import { DashboardGeneralDateText } from '../DashboardGeneralDateText';
import { DashboardCouponsTotalItem } from './DashboardCouponsTotalItem';
import {
  changeDashboardDateFilterStoreAction,
  fetchCouponReportByFilter,
} from '../../dashboard-actions';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { openSnackbarAction } from '../../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../../shared/components/snackbar/snackbar-types';
import { DashboardCouponTypeBar } from './DashboardCouponTypeBar';
import { CouponTypes } from '../../../tenant-subscription-plans/tenant-subs-plan-types';
import { formatterDateFilter } from '../../../../shared/utils';
import { DateRangePicker } from '../../../../shared/components/DateRangePicker';
import { DateFilter } from '../../../../shared/constants';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { CouponRedemptionsTable } from './CouponRedemptionsTable';
import { DashboardUsedCouponsByGruop } from './DashboardUsedCouponsByGruop';

export const DashboardCouponsTab: React.FC = () => {
  const { tenant } = useAuth();
  const [totalUsed, setTotalUsed] = useState(0);
  const [totalSharedUsed, setTotalSharedUsed] = useState(0);
  const { from, to } = useStore(changeDashboardDateFilterStore);
  const [data, setData] = useState<
    {
      coupon_type: CouponTypes;
      coupon_redemptions: {
        id: number;
        created_at: Date | null;
        updated_at: Date | null;
        coupon_id: number;
        user_id: number;
      }[];
      _count: {
        coupon_redemptions: number;
      };
    }[]
  >([]);

  const [callFetch, loading] = useCallAction(fetchCouponReportByFilter, {
    onCompleted(event) {
      setTotalUsed(event.totalRedemptionsCount);
      setTotalSharedUsed(event.sharedCouponsWithRedemptionsCount);
      setData(event.gruopByCouponType);
    },
    onError(event) {
      openSnackbarAction(event.message, TypeMessage.ERROR);
    },
  });

  useEffect(() => {
    if (tenant) {
      callFetch({ tenant, from, to });
    }
  }, [tenant, callFetch, from, to]);

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Informe general de cupones</Typography>
              <Box display="flex" alignItems="center">
                <DashboardGeneralDateText />
                <DateRangePicker
                  onChange={(range) => {
                    const filters = formatterDateFilter(
                      range.startDate,
                      range.endDate,
                      range.key as DateFilter,
                    );
                    changeDashboardDateFilterStoreAction(
                      filters.startDate,
                      filters.endDate,
                      range.key as DateFilter,
                    );
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardCouponsTotalItem
              title="Total de cupones usados"
              loading={loading}
              total={totalUsed}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardCouponsTotalItem
              title="Total de cupones que fueron compartidos y usados"
              loading={loading}
              total={totalSharedUsed}
            />
          </Grid>
          <Grid item xs={12}>
            <DashboardUsedCouponsByGruop loading={loading} />
          </Grid>
          <Grid item xs={12}>
            <DashboardCouponTypeBar data={data} loading={loading} />
          </Grid>
          <Grid item xs={12}>
            <CouponRedemptionsTable />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
