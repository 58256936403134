export const formatFrequencyPlan = (frequency: 'YEAR' | 'MONTH'): string => {
  switch (frequency) {
    case 'YEAR':
      return 'Anual';
    case 'MONTH':
      return 'Mensual';
  }
};

export const formatStatus = (frequency: 'ACTIVE' | 'INACTIVE'): string => {
  switch (frequency) {
    case 'ACTIVE':
      return 'Activo';
    case 'INACTIVE':
      return 'Inactivo';
  }
};
