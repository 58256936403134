import moment from 'moment';
import { TableColumnType } from '../../../shared/components/table/table-types';
import { TenantSubscriptionPlan } from '../tenant-subs-plan-types';
import { Box, Container, Link } from '@mui/material';
import { TableDefault } from '../../../shared/components/table/TableDefault';
import { formatNumber } from '../../../shared/utils/number-utils';
import { useNavigate } from 'react-router-dom';
import { formatFrequencyPlan, formatStatus } from '../../../shared/utils/utils';
import {
  ColumnStatus,
  ColumnTitle,
} from '../../../shared/components/text/Typographys';

interface PlansTableProps {
  data: TenantSubscriptionPlan[];
  total: number;
  loading: boolean;
  pagination: {
    pageSize: number;
    page: number;
  };
  hanChangePagination: (value: { pageSize: number; page: number }) => void;
}

export const PlansTable: React.FC<PlansTableProps> = ({
  data,
  loading,
  total,
  pagination,
  hanChangePagination,
}) => {
  const navigate = useNavigate();

  const COLUMNS: TableColumnType<TenantSubscriptionPlan>[] = [
    {
      columnName: <ColumnTitle textAlign="left">Nombre</ColumnTitle>,
      columnValue: null,
      columnComponent: (item) => (
        <Link
          onClick={() => navigate(`/plans/${item.id}`)}
          display={'flex'}
          align="left"
          sx={{ cursor: 'pointer' }}>
          {item.title} {item.stripe && '🎟️'}
        </Link>
      ),
    },
    {
      columnName: <ColumnTitle>Precio</ColumnTitle>,
      columnValue: (item) => formatNumber(item.price) || '',
    },
    {
      columnName: <ColumnTitle>Frecuencia</ColumnTitle>,
      columnValue: (item) =>
        item?.frequency ? formatFrequencyPlan(item.frequency) : '',
    },
    {
      columnName: <ColumnTitle>Status</ColumnTitle>,
      columnValue: null,
      columnComponent: (item) => (
        <ColumnStatus active={item.status === 'ACTIVE'}>
          {item?.status ? formatStatus(item.status) : ''}
        </ColumnStatus>
      ),
    },
    {
      columnName: <ColumnTitle>Premium</ColumnTitle>,
      columnValue: (item) => (item?.is_premium === true ? 'Sí' : 'No'),
    },
    {
      columnName: <ColumnTitle>Creado</ColumnTitle>,
      columnValue: (item) => moment(item.created_at).format('DD/MM/YYYY') || '',
    },
    {
      columnName: <ColumnTitle>Stripe</ColumnTitle>,
      columnValue: (item) => (item.stripe ? '✅' : '❌'),
    },
    {
      columnName: <ColumnTitle>Open Pay</ColumnTitle>,
      columnValue: (item) => (item.open_pay ? '✅' : '❌'),
    },
    {
      columnName: <ColumnTitle>Paypal</ColumnTitle>,
      columnValue: (item) => (item.paypal ? '✅' : '❌'),
    },
  ];

  return (
    <Box
      style={{
        borderRadius: '16px',
        background: 'white',
        paddingTop: '16px',
        paddingBottom: '16px',
      }}>
      <TableDefault
        items={data}
        count={total}
        columns={COLUMNS}
        page={pagination.page}
        pageSize={pagination.pageSize}
        loading={loading}
        messageLoading={'Cargando planes...'}
        handleChangePage={(page) => {
          hanChangePagination({
            ...pagination,
            page: page + 1,
          });
        }}
        showPagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        handleChangeRowPerPage={(e) => {
          hanChangePagination({
            ...pagination,
            pageSize: parseInt(e.target.value, 10),
            page: 1,
          });
        }}
      />
    </Box>
  );
};
