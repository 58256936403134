import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import {
  changeTenantStatusDialogStore,
  changeTenantUserStatusDialogStore,
  createTenantDialogStore,
  createTenantUserInvitationDialogStore,
  updateGlobalUserEvent,
  updateGlobalUserEventError,
  fetchTenantErrorEvent,
  fetchTenantEvent,
  updateTenantErrorEvent,
  updateTenantEvent,
  updateTenantUserDialogStore,
  fetchGlobalUserEvent,
  fetchGlobalUserEventError,
} from './tenant-events';
import { client, clientUpload } from '../../shared/apollo';
import {
  Tenant,
  TenantDocument,
  TenantQuery,
  TenantQueryVariables,
  TenantUser,
  UpdateTenantDocument,
  UpdateTenantMutation,
  UpdateTenantMutationVariables,
} from '../../shared/types/generated';
import { tenantUserTableFilterStore } from './tenant-stores';
import { TenantUserDetailsFormType } from './tenant-types';
import axios from 'axios';
import { GlobalUserJoinSocialProvider } from '../user/user-types';

export const createTenantDialogStoreAction = createStoreAction(
  createTenantDialogStore,
  (prev, isOpen: boolean) => ({
    ...prev,
    isOpen,
  }),
);

export const createTenantUserInvitationDialogStoreAction = createStoreAction(
  createTenantUserInvitationDialogStore,
  (prev, isOpen: boolean) => ({
    ...prev,
    isOpen,
  }),
);

export const fetchTenant = createAction(
  fetchTenantEvent,
  fetchTenantErrorEvent,
  async (id: string) => {
    const response = await client.query<TenantQuery, TenantQueryVariables>({
      query: TenantDocument,
      fetchPolicy: 'network-only',
      variables: {
        tenantId: id,
      },
    });

    return response.data;
  },
);

export const updateTenantAction = createAction(
  updateTenantEvent,
  updateTenantErrorEvent,
  async (variables: UpdateTenantMutationVariables) => {
    const response = await clientUpload.mutate<
      UpdateTenantMutation,
      UpdateTenantMutationVariables
    >({
      mutation: UpdateTenantDocument,
      variables,
    });

    return {
      tenant: response.data?.updateTenant as Tenant,
    };
  },
);

export const changeTenantStatusStoreAction = createStoreAction(
  changeTenantStatusDialogStore,
  (prev, isOpen: boolean, tenant: Tenant | undefined) => ({
    ...prev,
    isOpen,
    tenant,
  }),
);

export const changeTenantUserStatusStoreAction = createStoreAction(
  changeTenantUserStatusDialogStore,
  (prev, isOpen: boolean, tenantUser: TenantUser | undefined) => ({
    ...prev,
    isOpen,
    tenantUser,
  }),
);

export const updateTenantUserStoreAction = createStoreAction(
  updateTenantUserDialogStore,
  (prev, isOpen: boolean, tenantUser: TenantUser | undefined) => ({
    ...prev,
    isOpen,
    tenantUser,
  }),
);

export const tenantUserTableFilterStoreAction = createStoreAction(
  tenantUserTableFilterStore,
  (prev, provider: string, from: string, to: string, subscribedUsers: boolean) => ({
    ...prev,
    provider,
    from,
    to,
    subscribedUsers,
  }),
);

export const updateGlobalUser = createAction(
  updateGlobalUserEvent,
  updateGlobalUserEventError,
  async (data: {
    tenant: Tenant | undefined | null;
    userId: string | number;
    user: TenantUserDetailsFormType;
    file: File | null;
  }) => {
    const { tenant, user, userId, file } = data;

    const form = new FormData();
    form.append('user', JSON.stringify({ ...user, id: userId }));

    if (file) {
      form.append('file', file);
    }

    const url = `${tenant?.webhook}/api/user/update`;

    try {
      await axios({
        method: 'post',
        url,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 60000,
      });
    } catch (error: any) {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      }

      throw new Error('Error al actualizar el usuario');
    }
  },
);

export const fetchGlobalUserAction = createAction(
  fetchGlobalUserEvent,
  fetchGlobalUserEventError,
  async (data: { tenant: Tenant; id: string }) => {
    const { tenant, id } = data;
    const response = await axios.get<GlobalUserJoinSocialProvider>(
      `${tenant.webhook}/api/user/${id}`,
      {
        timeout: 60000,
      },
    );

    return response.data;
  },
);
