import React from 'react';
import { DashboardCardItem } from '../DashboardCardItem';
import { useStore } from '@cobuildlab/react-simple-state';
import {
  changeDashboardDateFilterStore,
  fetchUsersCountStore,
} from '../../dashboard-events';
import { getDateFilterTitleText } from '../../dashboard-utils';
import { Typography } from '@mui/material';

export const DashboardRegisterUserItem: React.FC = () => {
  const { loading, users } = useStore(fetchUsersCountStore);
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);

  const content = users.reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);

  return (
    <DashboardCardItem
      title={getDateFilterTitleText(from, to, filter)}
      content={content}
      loading={loading}
    />
  );
};
