import { FC, ReactNode } from 'react';
import {
  Tooltip as MuiTooltip,
  styled,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: '50vw',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    overflowY: 'auto',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
}));

interface TooltipHelperProps {
  value: ReactNode;
}

export const TooltipHelper: FC<TooltipHelperProps> = ({ value }) => {
  return (
    <Tooltip
      title={value}
      placement="bottom"
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 20],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
            },
          },
        ],
        anchorEl: {
          getBoundingClientRect: () => ({
            top: window.innerHeight / 8,
            left: window.innerWidth / 2,
            width: 0,
            height: 0,
            bottom: 0,
            right: 0,
            x: 0,
            y: 0,
            toJSON: () => ({}),
          }),
        },
      }}>
      <HelpOutlineIcon
        htmlColor="#424242"
        sx={{ marginLeft: 1, fontSize: 18 }}
      />
    </Tooltip>
  );
};
