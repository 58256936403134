import { createAction } from '@cobuildlab/react-simple-state';
import {
  changeCommentStatusErrorEvent,
  changeCommentStatusEvent,
  changeReplyCommentStatusErrorEvent,
  changeReplyCommentStatusEvent,
  fetchCommentErrorEvent,
  fetchCommentEvent,
  fetchCommentsErrorEvent,
  fetchCommentsEvent,
} from './comment-events';
import axios from 'axios';
import { fetchAuthStore } from '../auth/auth-events';
import { PostComment, Comment, CommentStatuses } from './comment-types';

export const fetchComments = createAction(
  fetchCommentsEvent,
  fetchCommentsErrorEvent,
  async (page: number, pageSize: number) => {
    const { tenant } = fetchAuthStore.get();
    const response = await axios.get<{
      postComments: PostComment[];
      count: number;
    }>(`${tenant?.webhook}/api/post/comments`, { params: { page, pageSize } });

    const data = response.data;

    return {
      comments: data.postComments,
      count: data.count,
    };
  },
);

export const fetchComment = createAction(
  fetchCommentEvent,
  fetchCommentErrorEvent,
  async (id: string, page: number, pageSize: number) => {
    const { tenant } = fetchAuthStore.get();
    const response = await axios.get<{
      comments: Comment[];
      count: number;
    }>(`${tenant?.webhook}/api/post/comments/${id}`, {
      params: { page, pageSize },
    });

    const data = response.data;

    return {
      comments: data.comments,
      count: data.count,
    };
  },
);

export const changeCommentStatus = createAction(
  changeCommentStatusEvent,
  changeCommentStatusErrorEvent,
  async (id: number, status?: CommentStatuses) => {
    const { tenant } = fetchAuthStore.get();

    const response = await axios.post<{
      comment: Comment;
    }>(`${tenant?.webhook}/api/post/comment/${id}/update`, {
      status,
    });

    const data = response.data;

    return {
      comment: data.comment,
    };
  },
);

export const changeReplyCommentStatus = createAction(
  changeReplyCommentStatusEvent,
  changeReplyCommentStatusErrorEvent,
  async (id: number, status?: CommentStatuses) => {
    const { tenant } = fetchAuthStore.get();

    const response = await axios.post<{
      comment: Comment;
    }>(`${tenant?.webhook}/api/post/reply-comment/${id}/update`, {
      status,
    });

    const data = response.data;

    return {
      comment: data.comment,
    };
  },
);
