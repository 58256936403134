import React from 'react';
import { useEvent } from '@cobuildlab/react-simple-state';
import { CouponRedemptionGruopByCouponType } from '../../dashboard-types';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import { TableDefaultSticky } from '../../../../shared/components/table/TableDefaultSticky';
import { fetchCouponReportByFilterEvent } from '../../dashboard-events';
import { formatterCouponType } from '../../dashboard-utils';

type DashboardWinnerNotesProps = {
  loading?: boolean;
};
export const DashboardUsedCouponsTable: React.FC<DashboardWinnerNotesProps> = ({
  loading = false,
}) => {
  const { gruopByCouponType: data } = useEvent(fetchCouponReportByFilterEvent);
  const total = data.length ?? 0;

  const COLUMNS: TableColumnType<CouponRedemptionGruopByCouponType>[] = [
    {
      columnName: 'Tipo de cupón',
      columnValue: (item) => formatterCouponType(item.coupon_type),
    },
    {
      columnName: 'Cupones redimidos',
      columnValue: (item) => item._count.coupon_redemptions,
    },
  ];

  return (
    <TableDefaultSticky
      count={total}
      items={data}
      columns={COLUMNS}
      page={1}
      pageSize={total}
      loading={loading}
      messageLoading={'Cargando...'}
    />
  );
};
