import { createEvent, createStore } from '@cobuildlab/react-simple-state';
import { AuthStep, AuthStore } from './auth-types';
import { universalCookies } from '../../shared/cookies';
import { DEFAULT_TIMEZONE, USER_COOKIE } from '../../shared/constants';
import { Tenant, TenantUser, User } from '../../shared/types/generated';
import { getEmail, getIsLogin, getStep } from './auth-utils';

export const authStore = createStore<AuthStore>({
  initialValue: {
    email: getEmail(),
    isLogin: getIsLogin(),
  },
});

export const authStepStore = createStore<{ form: AuthStep }>({
  initialValue: {
    form: getStep(),
  },
});

export interface InitialStateType {
  token: null | string;
}

const INITIAL_STATE = {
  token: universalCookies.get(USER_COOKIE),
};

export const OnTokenEvent = createEvent<InitialStateType>({
  initialValue: INITIAL_STATE,
});

export const fetchAuthStore = createStore<{
  user: User | undefined | null;
  tenant: Tenant | undefined | null;
  tenantUsers: TenantUser[];
}>({
  initialValue: {
    user: undefined,
    tenant: undefined,
    tenantUsers: [],
  },
  reducer: (prevState, newState) => {
    const user = newState.user;
    const tenantUsers = (newState.user?.tenantUsers || []) as TenantUser[];
    let tenant = tenantUsers.find((a) => a.isSelected)?.tenant;


    if (!tenant && tenantUsers.length) {
      const [a] = tenantUsers;
      tenant = { ...a.tenant, timezone: tenantUsers[0]?.tenant?.timezone || DEFAULT_TIMEZONE };
    }

    return {
      ...prevState,
      user,
      tenant,
      tenantUsers,
    };
  },
});
