import { createEvent } from '@cobuildlab/react-simple-state';
import { TenantSubsPlanStripeCouponType } from '../tenant-subs-plan-types';

export const createSharedCouponEvent = createEvent<void>();
export const createSharedCouponEventError = createEvent<Error>();

export const editCouponEvent = createEvent<TenantSubsPlanStripeCouponType>();
export const editCouponEventError = createEvent<Error>();

export const enableOrDisableStripeCouponEvent = createEvent<TenantSubsPlanStripeCouponType>();
export const enableOrDisableStripeCouponEventError = createEvent<Error>();
