import React from 'react';
import { Box, Tab, tabClasses, Tabs, Stack, Container } from '@mui/material';
import { styled } from '@mui/styles';
import { useAuth } from '../auth/auth-hook';
import { CustomHeader } from '../../shared/components/layout/CustomHeader';
import { TenantSubscriptionPlans } from './TenantSubscriptionPlans';
import { CouponsView } from './coupons/CouponsView';

type TenantSubsPlanViewProps = {
  // children: ReactElement;
};

export const TenantSubsPlanViewLayout: React.FC<TenantSubsPlanViewProps> = () => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const { user, tenant } = useAuth();

  const TabItem = styled(Tab)({
    position: 'relative',
    borderRadius: '30px !important',
    textAlign: 'center',
    transition: 'all .5s',
    padding: '8px 10px !important',
    color: '#BBBBBB',
    height: '35px !important',
    margin: '6px 2px',
    float: 'none',
    lineHeight: '14px',
    fontSize: '14px',
    fontWeight: '700',
    minHeight: '38px',
    textTransform: 'capitalize',
    [`&.${tabClasses.selected}, &:hover`]: {
      color: tenant?.identityColor ?? 'black',
      backgroundColor: tenant?.identityColor + '20' ?? 'black',
    },
  });

  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: number | null,
  ): void => {
    setCurrentTab(newValue ?? 0);
  };

  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={
          'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
        }
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          padding: '16px 16px',
          background: 'white',
        }}>
        <Stack direction="column" width={'100%'} spacing={2}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              paddingBottom: 1,
            }}>
            <Tabs
              sx={{
                padding: { sm: '0px 10px !important' },

                ['& .MuiTabs-indicator']: {
                  display: 'none !important',
                },
              }}
              value={currentTab}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              variant="scrollable"
              allowScrollButtonsMobile>
              <TabItem label="Planes" />
              <TabItem label="Cupones" />
            </Tabs>
          </Box>
          {currentTab === 0 && <TenantSubscriptionPlans />}
          {currentTab === 1 && <CouponsView />}
        </Stack>
      </Container>
    </>
  );
};
