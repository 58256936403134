import { createStore } from '@cobuildlab/react-simple-state';

export const couponFilterStore = createStore<{
  status: string;
  expired: boolean;
}>({
  initialValue: {
    status: 'ACTIVE',
    expired: false,
  },
});
