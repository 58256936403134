import { InferType } from 'yup';
import { createCouponSchema, editCouponSchema } from './tenant-subs-plan-validator';

export enum TenantSubsPlanDetailsTabs {
  DETAILS = 'DETAILS',
}

export enum CouponTypes {
  DISCOUNT_PERCENT = 'DISCOUNT_PERCENT',
  DISCOUNT_PERCENT_AND_DURATION = 'DISCOUNT_PERCENT_AND_DURATION',
  DISCOUNT_PERCENT_AND_VOLUME = 'DISCOUNT_PERCENT_AND_VOLUME',
  DISCOUNT_PERCENT_WITH_MONTHS = 'DISCOUNT_PERCENT_WITH_MONTHS',
  DISCOUNT_PERCENT_AND_DURATION_AND_VOLUME = 'DISCOUNT_PERCENT_AND_DURATION_AND_VOLUME',
  DISCOUNT_PERCENT_AND_DURATION_WITH_MONTHS = 'DISCOUNT_PERCENT_AND_DURATION_WITH_MONTHS',
  DISCOUNT_PERCENT_AND_VOLUME_WITH_MONTHS = 'DISCOUNT_PERCENT_AND_VOLUME_WITH_MONTHS',
  DISCOUNT_PERCENT_AND_DURATION_AND_VOLUME_WITH_MONTHS = 'DISCOUNT_PERCENT_AND_DURATION_AND_VOLUME_WITH_MONTHS',
  DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
  DISCOUNT_AMOUNT_AND_DURATION = 'DISCOUNT_AMOUNT_AND_DURATION',
  DISCOUNT_AMOUNT_AND_VOLUME = 'DISCOUNT_AMOUNT_AND_VOLUME',
  DISCOUNT_AMOUNT_WITH_MONTHS = 'DISCOUNT_AMOUNT_WITH_MONTHS',
  DISCOUNT_AMOUNT_AND_DURATION_AND_VOLUME = 'DISCOUNT_AMOUNT_AND_DURATION_AND_VOLUME',
  DISCOUNT_AMOUNT_AND_DURATION_WITH_MONTHS = 'DISCOUNT_AMOUNT_AND_DURATION_WITH_MONTHS',
  DISCOUNT_AMOUNT_AND_VOLUME_WITH_MONTHS = 'DISCOUNT_AMOUNT_AND_VOLUME_WITH_MONTHS',
  DISCOUNT_AMOUNT_AND_DURATION_AND_VOLUME_WITH_MONTHS = 'DISCOUNT_AMOUNT_AND_DURATION_AND_VOLUME_WITH_MONTHS',
}

export interface StripeProvider {
  id: number;
  product_id: string;
  price_id: string;
  plan_id: number;
  created_at: string;
  updated_at: string;
}

export interface PayPalProvider {
  id: number;
  status: 'ACTIVE' | 'INACTIVE';
  plan_id: number;
  paypal_id: string;
  created_at: string;
  updated_at: string;
}

export interface OpenPayProvider {
  id: number;
  status: 'ACTIVE' | 'INACTIVE';
  plan_id: number;
  open_pay_id: string;
  first_payment_price: number;
  created_at: string;
  updated_at: string;
}

export interface TenantSubscriptionPlan {
  id: string;
  title: string;
  sub_title: string | null;
  description: string | null;
  price: number;
  status: 'ACTIVE' | 'INACTIVE';
  frequency: 'YEAR' | 'MONTH';
  subscription_billing_is_required: boolean;
  is_premium: boolean;
  helper_text: string | null;
  created_at: string;
  updated_at: string;
  stripe: StripeProvider | null;
  paypal: PayPalProvider | null;
  open_pay: OpenPayProvider | null;
}

export interface TenantSubscriptionListType {
  data: TenantSubscriptionPlan[];
  total: number;
}

export type CreateCouponSchemaFormType = InferType<typeof createCouponSchema> & {
  plans: string[];
};

export type EditCouponSchemaFormType = InferType<typeof editCouponSchema> & {
  plans: string[];
};
export interface TenantSubsPlanStripeCouponType {
  id: number;
  coupon: string;
  created_at: string;
  updated_at: string;
  status: 'ACTIVE' | 'INACTIVE';
  discount_percent?: number;
  discount_amount?: number;
  max_redemptions?: number;
  start_date?: string | null;
  end_date?: string | null;
  duration_in_months?: number;
  stripe_id: number;
  stripe: StripeProvider;
  coupon_type: CouponTypes;
  is_for_sharing: boolean;
  is_public: boolean;
  coupon_plans: {
    id: number;
    paypal_coupon_plan: string;
    coupon_id: number;
    plan_id: number;
    created_at: string; 
    updated_at: string;     
  }[];
}

export interface TenantSubsPlanStripeCouponListType {
  data: TenantSubsPlanStripeCouponType[];
  total: number;
}
