import { FC, useEffect, useState } from 'react';
import { GlobalSubscriptionTable } from '../../../subscription/subscription-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Tabs,
  Tab,
  tabClasses,
  styled,
  lighten,
  Box,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { InputDefault } from '../../../../shared/components/inputs/InputDefault';
import moment from 'moment';
import { useAuth } from '../../../auth/auth-hook';
import { useBreakPoint } from '../../../../shared/hooks/useBreakPoint';

const TabItem = styled(Tab)<{ tenantColor?: string }>(({ tenantColor }) => ({
  position: 'relative',
  borderRadius: '30px !important',
  textAlign: 'center',
  transition: 'all .5s',
  padding: '8px 10px !important',
  color: '#BBBBBB',
  height: '35px !important',
  margin: '12px 2px',
  float: 'none',
  lineHeight: '14px',
  fontSize: '14px',
  fontWeight: '700',
  minHeight: '38px',
  textTransform: 'capitalize',
  [`&.${tabClasses.selected}, &:hover`]: {
    color: tenantColor ?? 'black',
    backgroundColor: tenantColor ? tenantColor + '20' : 'black',
  },
}));

interface SubscriptionUserModalProps {
  open: boolean;
  onClose: () => void;
  userSubscription?: GlobalSubscriptionTable;
}

export const SubscriptionUserModal: FC<SubscriptionUserModalProps> = ({
  onClose,
  open,
  userSubscription,
}) => {
  const { tenant } = useAuth();
  const [currentTab, setCurrentTab] = useState(0);
  const { isBelow } = useBreakPoint('sm');
  const isValidSubscriptionBilling =
    !!userSubscription?.subscription_billings &&
    !!userSubscription?.subscription_billings.address &&
    userSubscription?.subscription_billings.address !== '';

  const handleChangeTab = (
    event: React.SyntheticEvent | null,
    newValue: number | null,
  ): void => {
    setCurrentTab(newValue ?? 0);
  };

  useEffect(() => {
    if (!open) {
      setCurrentTab(0);
    }
  }, [open]);

  if (!userSubscription) return null;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth keepMounted>
      <DialogTitle color="black" fontWeight={700}>
        Detalles del subscriptor
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          onClose();
        }}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogContent dividers>
        <Box>
          <Tabs
            sx={{
              ['& .MuiTabs-indicator']: {
                display: 'none !important',
              },
            }}
            value={currentTab}
            onChange={handleChangeTab}
            aria-label="scrollable auto tabs"
            variant="fullWidth"
            allowScrollButtonsMobile
            {...(isBelow && {
              variant: 'scrollable',
              scrollButtons: false,
            })}>
            <TabItem
              tenantColor={tenant?.identityColor as string}
              label="Datos del usuario"
            />
            <TabItem
              tenantColor={tenant?.identityColor as string}
              label="Datos de la subscripción"
            />
            {isValidSubscriptionBilling && (
              <TabItem
                tenantColor={tenant?.identityColor as string}
                label="Datos de facturación"
              />
            )}
          </Tabs>
        </Box>
        <Box sx={{ pt: 3, pb: 5 }}>
          <Grid container spacing={2}>
            {currentTab === 0 && (
              <>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Nombre"
                    value={userSubscription?.user_name}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Email"
                    value={userSubscription?.user_email}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Proveedor"
                    value={userSubscription?.provider ?? 'SYSTEM'}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Status"
                    value={userSubscription?.user_status}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </>
            )}

            {currentTab === 1 && (
              <>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Plan"
                    value={userSubscription?.plan_title}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Status"
                    value={userSubscription?.status}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Metodo de pago"
                    value={userSubscription?.payment_method}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Frencuencia"
                    value={
                      userSubscription?.plan_frequency === 'YEAR'
                        ? 'Anual'
                        : 'Mensual'
                    }
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Fecha de inicio"
                    value={moment(userSubscription?.period_start_date).format(
                      'DD/MM/YYYY',
                    )}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Fecha de fin"
                    value={moment(userSubscription?.period_end_date).format(
                      'DD/MM/YYYY',
                    )}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Nota de la subscripción"
                    value={
                      userSubscription?.subscription_url &&
                      userSubscription.subscription_url !== ''
                        ? userSubscription.subscription_url
                        : 'N/A'
                    }
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDefault
                    labelName="Id de la transacción"
                    value={userSubscription?.transaction_id}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </>
            )}

            {currentTab === 2 &&
              userSubscription?.subscription_billings &&
              isValidSubscriptionBilling && (
                <>
                  <Grid item xs={12} md={6}>
                    <InputDefault
                      labelName="Dirección"
                      value={userSubscription.subscription_billings.address}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputDefault
                      labelName="Colonia"
                      value={userSubscription.subscription_billings.address1}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputDefault
                      labelName="Ciudad"
                      value={userSubscription.subscription_billings.city}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputDefault
                      labelName="Estado"
                      value={userSubscription.subscription_billings.state}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputDefault
                      labelName="País"
                      value={userSubscription.subscription_billings.country}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputDefault
                      labelName="Código postal"
                      value={userSubscription.subscription_billings.postal_code}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputDefault
                      labelName="Teléfono"
                      value={userSubscription.subscription_billings.phone}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputDefault
                      labelName="Teléfono secundario"
                      value={userSubscription.subscription_billings.phone1}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </>
              )}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
