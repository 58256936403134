import { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import { useBreakPoint } from '../../../../shared/hooks/useBreakPoint';


const TableCell = styled(MuiTableCell)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
}));

const couponRules = [
  {
    rule: 'Vigencia de cupones creados',
    description:
      'Fecha de inicio y fin para poder usar el cupon, fuera de estas fechas no debe poder usar el cupon',
    monthly: 'SI',
    yearly: 'SI',
  },
  {
    rule: 'No combinar con otras promociones',
    description:
      'En caso de existir 2 o más cupones/promociones vigentes, poder optar por que se sumen o no',
    monthly: 'NO',
    yearly: 'NO',
  },
  {
    rule: 'Uso por volumen',
    description:
      'Opción para marcar que el cupon se puede usar N cantidad de veces.',
    monthly: 'SI',
    yearly: 'SI',
  },
  {
    rule: 'Para compartir con amigos y familiares',
    description:
      'No aplica para el suscriptor actual, es para que se pueda compartir',
    monthly: 'SI',
    yearly: 'SI',
  },
  {
    rule: 'Promoción de regalo',
    description: 'El cupon ofrece un regalo y no un descuento',
    monthly: 'NO',
    yearly: 'NO',
  },
  {
    rule: 'Promoción de descuento',
    description: 'El cupon ofrece un descuento en el monto de la suscripción',
    monthly: 'SI',
    yearly: 'SI',
  },
  {
    rule: 'Promoción dirigida a un grupo específico',
    description:
      'Por ejemplo un cupon solo para suscriptores del plan estandar, el objetivo es invitarlos a cambiar su plan al anual',
    monthly: 'SI',
    yearly: 'SI',
  },
  {
    rule: 'Para ofrecer meses gratis',
    description:
      'Cupon para ofrecer meses gratis y despues se paga la tarifa base de los planes estandar y estudiantil.',
    monthly: 'SI',
    yearly: 'NO',
  },
  {
    rule: 'Regalo + descuento',
    description: 'Cupon que ofrece regalo + un descuento promocional',
    monthly: 'NO',
    yearly: 'NO',
  },
  {
    rule: 'Cupon solo para usuarios nuevos',
    description:
      'El cupon solo debe usarse si no existe una suscripción activa',
    monthly: 'SI',
    yearly: 'SI',
  },
  {
    rule: 'Uso general',
    description: 'Cupon se aplica a cualquier plan',
    monthly: 'SI',
    yearly: 'SI',
  },
];

export const CouponRules: FC = () => {
  const { isBelow } = useBreakPoint('md');
  return (
    <TableContainer
      sx={{
        maxHeight: '600px',
        overflowY: 'auto',
        pointerEvents: 'auto',
      }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>
              Reglas para cupones
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                ...(isBelow && { display: 'none' }),
              }}>
              Descripción / Detalles
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}>
              Aplicable para mensual
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}>
              Aplicable para anual
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {couponRules.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.rule}</TableCell>
              <TableCell
                sx={{
                  ...(isBelow && { display: 'none' }),
                }}>
                {row.description}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  verticalAlign: 'middle',
                }}>
                {row.monthly}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  verticalAlign: 'middle',
                }}>
                {row.yearly}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
