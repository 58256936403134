import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useStylesCustom } from '../DashboardCardItem';
import { DashboardUsedCouponsTable } from './DashboardUsedCouponsTable';
import { DashboardUsedCouponsPie } from './DashboardUsedCouponsPie';

interface Props {
  loading?: boolean;
}
export const DashboardUsedCouponsByGruop: React.FC<Props> = ({ loading }) => {
  const classes = useStylesCustom();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={1}>
          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              component={'div'}
              sx={{ marginBottom: { xs: '10px', md: '0px' } }}
              fontSize={20}
              fontWeight={700}
            >
              Cupones usados por tipo
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{
            xs: 2,
            md: 1,
          }}
        >
          <Card
            elevation={2}
            style={{
              border: '1px solid #BBBBBB',
              padding: '16px',
              borderRadius: '16px',
              boxShadow: '0px 0px 8px 0px #00000014',
            }}
          >
            <CardContent className={classes.content}>
              <DashboardUsedCouponsTable loading={loading} />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{
            xs: 1,
            md: 2,
          }}
        >
          <Card
            elevation={2}
            style={{
              border: '1px solid #BBBBBB',
              padding: '16px',
              borderRadius: '16px',
              boxShadow: '0px 0px 8px 0px #00000014',
            }}
          >
            <CardContent className={classes.content}>
              <DashboardUsedCouponsPie />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
