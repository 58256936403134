import React, { useEffect, useState, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
} from '@mui/material';
import { editCouponSchema } from '../../tenant-subs-plan-validator';
import { handleFormErrors } from '../../../../shared/utils/errors';
import { Close as CloseIcon } from '@mui/icons-material';
import { DatePickerDefault } from '../../../../shared/components/inputs/DatePickerDefault';
import moment from 'moment';
import { InputDefault } from '../../../../shared/components/inputs/InputDefault';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import { SelectDefault } from '../../../../shared/components/inputs/SelectDefault';
import {
  EditCouponSchemaFormType,
  TenantSubsPlanStripeCouponType,
} from '../../tenant-subs-plan-types';
import { TenantSubscriptionListEvent } from '../../tenant-subs-plan-events';
import { useEvent } from '@cobuildlab/react-simple-state';

interface EditCouponModalProps {
  coupon: TenantSubsPlanStripeCouponType;
  open: boolean;
  handleClose: () => void;
  loading?: boolean;
  buttonColor?: string | null;
  onSubmit: (data: EditCouponSchemaFormType) => void;
}

export const EditCouponModal: React.FC<EditCouponModalProps> = ({
  coupon,
  handleClose,
  open,
  loading = false,
  buttonColor,
  onSubmit,
}) => {
  const plans = useEvent(TenantSubscriptionListEvent);
  const couponPlans = coupon.coupon_plans.map((couponPlan) =>
    couponPlan.plan_id.toString(),
  );
  const [planOptionSelected, setPlanOptionSelected] = useState<
    { value: string; label: string }[]
  >(
    coupon.coupon_plans.length > 0
      ? couponPlans.map((plan) => {
          const planSelected = plans.data.find((p) => p.id.toString() === plan);
          return {
            value: plan,
            label: planSelected?.title ?? '',
          };
        })
      : [],
  );
  const [planOptions, setPlanOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(editCouponSchema),
    defaultValues: {
      name: coupon.coupon,
      maxRedemptions: coupon.max_redemptions,
      expirationDate: coupon.end_date
        ? moment(coupon.end_date).format('MM/DD/YYYY')
        : null,
      isPublic: coupon.is_public,
      isForSharing: coupon.is_for_sharing,
    },
  });

  const onFormSubmit = handleSubmit(
    async (dataForm) => {
      onSubmit({
        ...dataForm,
        plans: planOptionSelected.map((plan) => plan.value),
      });
    },
    (error) => {
      handleFormErrors(error);
    },
  );

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  useEffect(() => {
    if (plans && plans.data) {
      const options =plans.data.map((plan) => ({
        value: String(plan.id),
        label: plan.title,
      }));
      setPlanOptions(options);
    }
  }, [plans]);
  

  const filteredPlanOptions = useMemo(() => {
    return planOptions.filter(
      (plan) =>
        !planOptionSelected.some((selected) => selected.value === plan.value),
    );
  }, [planOptions, planOptionSelected]);

  const ActivePlans = (): JSX.Element => (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {planOptionSelected.map((item) => (
        <Chip
          key={item.value}
          label={item.label}
          variant="filled"
          size="small"
          sx={{ mt: 1 }}
          onDelete={() =>
            setPlanOptionSelected(
              planOptionSelected.filter(
                (selectedPlan) => selectedPlan.value !== item.value,
              ),
            )
          }
        />
      ))}
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        reset();
      }}
      fullWidth
      maxWidth="xs">
      <DialogTitle color="black" fontWeight={700}>
        Editar cupón
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          handleClose();
          reset();
        }}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={onFormSubmit}
        sx={{
          padding: 2,
          margin: '0 auto',
          maxWidth: '600px',
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <InputDefault
                  {...rest}
                  fullWidth
                  labelName="Nombre de cupón"
                  placeholder="Ingrese nombre del cupón"
                  variant="outlined"
                  type="text"
                  error={!!errors.maxRedemptions}
                  helperText={errors.maxRedemptions?.message}
                  onChange={(e) => {
                    const inputValue = e.target.value.toUpperCase();
                    const regex = /^[a-zA-Z0-9-]*$/;

                    if (regex.test(inputValue)) {
                      e.target.value = inputValue;
                      onChange(e);
                    }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectDefault
              fullWidth
              labelName="Planes"
              variant="outlined"
              placeholder="Seleccione un plan"
              type="text"
              options={filteredPlanOptions}
              onChangeData={(field, value) => {
                const newValue = {
                  value,
                  label: planOptions.find((plan) => plan.value === value)
                    ?.label as string,
                };

                const normalizedValue = planOptionSelected.filter(
                  (plan) => plan.value !== value,
                );
                const data = [newValue, ...normalizedValue];
                const uniqueArray = data.filter(
                  (val, index, self) =>
                    self.findIndex((item) => item.value === val.value) ===
                    index,
                );

                setPlanOptionSelected(uniqueArray);
              }}
            />
            <ActivePlans />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="maxRedemptions"
              control={control}
              render={({ field }) => (
                <InputDefault
                  {...field}
                  fullWidth
                  labelName="Máximo de Redenciones"
                  placeholder="Ingrese el máximo de redenciones"
                  variant="outlined"
                  type="number"
                  error={!!errors.maxRedemptions}
                  helperText={errors.maxRedemptions?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name={'expirationDate'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePickerDefault
                  labelName="Fecha de Vencimiento"
                  textFieldProps={{
                    helperText: errors.expirationDate?.message,
                    error: !!errors.expirationDate?.message,
                    placeholder: 'DD/MM/AAAA',
                  }}
                  value={value && value !== '' ? moment(value as string) : null}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <Controller
                name={'isForSharing'}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label="Permitir que este disponible para que cualquier usuario y se pueda compartir."
                    control={
                      <Checkbox
                        color="secondary"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                  />
                )}
              />
              <Controller
                name={'isPublic'}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ marginTop: 2 }}
                    label="Permitir que este disponible para que cualquier usuario."
                    control={
                      <Checkbox
                        color="secondary"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <ButtonDefault
              type="submit"
              fullWidth
              disabled={!isValid}
              isLoading={loading}
              style={{
                textTransform: 'inherit',
                fontWeight: 700,
                background: buttonColor ?? undefined,
                ...(!isValid && { background: '#d3d3d3' }),
              }}>
              Actualizar Cupón
            </ButtonDefault>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
