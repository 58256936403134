import { FC, useEffect, useState } from 'react';
import { Box, Chip, IconButton, InputAdornment, Stack } from '@mui/material';
import { InputDefault } from '../../../../shared/components/inputs/InputDefault';
import AddIcon from '@mui/icons-material/Add';

interface ShareCouponInputProps {
  labelName: string;
  onChange: (emails: string[]) => void;
  placeholder?: string;
}

export const ShareCouponInput: FC<ShareCouponInputProps> = ({
  labelName,
  onChange,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [emails, setEmails] = useState<string[]>([]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };

  const handleAddEmail = (): void => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(inputValue);
    const isRepeated = emails.includes(inputValue);

    if (isValidEmail && !isRepeated) {
      setEmails([...emails, inputValue]);
      setInputValue('');
    }
  };

  const handleDelete = (email: string): void => {
    setEmails(emails.filter((item) => item !== email));
  };

  const ActiveEmails = (): JSX.Element => (
    <Box>
      {emails.map((item, index) => (
        <Chip
          key={index}
          label={item}
          variant="filled"
          onDelete={() => handleDelete(item)}
        />
      ))}
    </Box>
  );

  useEffect(() => {
    onChange(emails);
  }, [emails, onChange]);

  return (
    <Stack direction="column" spacing={2}>
      <InputDefault
        variant="outlined"
        labelName={labelName}
        value={inputValue}
        onChange={handleEmailChange}
        fullWidth
        placeholder={placeholder}
        endIcon={
          <InputAdornment position="end">
            <IconButton onClick={handleAddEmail} edge="end">
              <AddIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <ActiveEmails />
    </Stack>
  );
};
